// import React, { useState } from "react";
// import { Navbar } from "flowbite-react";
// import { Link } from "react-router-dom";
// import serenotyLogo from "../../assets/serenotylogo.png";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? "backdrop-blur-sm bg-bb" : "backdrop-blur-lg bg-[#ffffffa2]"
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link
//               to="/"
//               className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary"
//             >
//               <span style={{ fontWeight: "semibold" }}>SERENOTY</span>&nbsp;
//               <span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/")
//               }
//             >
//               Dashboard
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/")
//               }
//             >
//               Dashboard
//             </button>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState } from "react";
// import { Navbar } from "flowbite-react";
// import { Link } from "react-router-dom";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? "backdrop-blur-sm bg-bb" : "backdrop-blur-lg bg-[#ffffffa2]"
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link
//               to="/"
//               className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary"
//             >
//               <span style={{ fontWeight: "semibold" }}>SERENOTY</span>&nbsp;
//               <span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/")
//               }
//             >
//               Dashboard
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/")
//               }
//             >
//               Dashboard
//             </button>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState } from "react";
// import { Navbar } from "flowbite-react";
// import { Link } from "react-router-dom";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? "backdrop-blur-sm bg-bb" : "backdrop-blur-lg bg-[#ffffffa2]"
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link
//               to="/"
//               className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary"
//             >
//               <span style={{ fontWeight: "semibold" }}>SERENOTY</span>&nbsp;
//               <span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center md:hidden py-2 space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/signup")
//               }
//             >
//               Sign Up
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com")
//               }
//             >
//               Sign In
//             </button>
//           </div>
//           <div className="md:flex items-center space-x-2 hidden md:flex">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/signup")
//               }
//             >
//               Sign Up
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com")
//               }
//             >
//               Sign In
//             </button>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState } from "react";
// import { Navbar } from "flowbite-react";
// import { Link } from "react-router-dom";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? "backdrop-blur-sm bg-bb" : "backdrop-blur-lg bg-[#ffffffa2]"
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <Link
//               to="/"
//               className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary"
//             >
//               <span style={{ fontWeight: "semibold" }}>SERENOTY</span>&nbsp;
//               <span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </Link>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com")
//               }
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-2.5 h-[1.9rem] py-2 border bg-bb hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-tt focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() =>
//                 (window.location.href = "https://app.serenoty.com/signup")
//               }
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState } from "react";
// import { Navbar } from "flowbite-react";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   const navbarClasses = `fixed top-0 left-0 w-full z-20 ${
//     isScrolled ? "backdrop-blur-sm bg-bb" : "backdrop-blur-lg bg-[#ffffffa2]"
//   } transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container banner-container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <a
//               href="/"
//               className="self-center whitespace-nowrap text-md md:text-2xl font-semibold text-primary"
//             >
//               <span style={{ fontWeight: "semibold" }}>SERENOTY</span>&nbsp;
//               <span style={{ fontWeight: 100, opacity: 0.4 }}>AI</span>
//             </a>
//           </div>
//           <div className="flex items-center space-x-4">
//             {/* Contact Link */}
//             <a
//               href="mailto:support@serenoty.com"
//               className="text-md text-primary hover:underline transition duration-300"
//             >
//               Contact
//             </a>
//             {/* Sign In and Sign Up buttons */}
//             <button
//               type="button"
//               className="inline-flex items-center px-3 py-2 border bg-[#edebe7] hover:bg-opacity-50 border-transparent text-sm font-semibold rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() => (window.location.href = "https://app.serenoty.com/login")}
//             >
//               Sign In
//             </button>
//             <button
//               type="button"
//               className="inline-flex items-center px-3 py-2 border bg-black hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
//               onClick={() => (window.location.href = "https://app.serenoty.com/signup")}
//             >
//               Sign Up
//             </button>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

// import React, { useState, useEffect } from "react";
// import { Navbar } from "flowbite-react";
// import "./navbar.css";

// const Navigation = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 0);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   // Keeping the background translucent with glass-like blur effect
//   const navbarClasses = `fixed top-0 left-0 w-full z-20 backdrop-blur-lg bg-[#ffffff50] transition-opacity duration-300 ease-in-out`;

//   return (
//     <div className="nav-container">
//       <Navbar fluid={true} rounded={true} className={navbarClasses}>
//         <div className="container mx-auto px-4 flex justify-between items-center">
//           {/* Logo Section */}
//           <a
//             href="/"
//             className="flex items-center text-lg md:text-2xl font-semibold text-primary whitespace-nowrap"
//           >
//             SERENOTY
//           </a>

//           {/* Full Menu for Both Desktop and Mobile */}
//           <div className="flex items-center space-x-4">
//             <a
//               href="mailto:support@serenoty.com"
//               className="text-md text-primary hover:underline transition duration-300"
//             >
//               Contact
//             </a>
//             <div className="flex space-x-2">
//               <button
//                 type="button"
//                 className="inline-flex items-center px-4 py-2 border bg-[#f4f4f4] hover:bg-[#e2e2e2] border-transparent text-sm font-semibold rounded-full text-gray-700"
//                 onClick={() => (window.location.href = "https://app.serenoty.com/login")}
//               >
//                 Log in
//               </button>
//               <button
//                 type="button"
//                 className="inline-flex items-center px-4 py-2 border bg-black hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-full text-white"
//                 onClick={() => (window.location.href = "https://app.serenoty.com/signup")}
//               >
//                 Sign up
//               </button>
//             </div>
//           </div>
//         </div>
//       </Navbar>
//     </div>
//   );
// };

// export default Navigation;

import React, { useState, useEffect } from "react";
import { Navbar } from "flowbite-react";
import "./navbar.css";

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Keeping the background translucent with glass-like blur effect
  const navbarClasses = `fixed top-0 left-0 w-full z-20 backdrop-blur-lg bg-[#ffffff50] transition-opacity duration-300 ease-in-out`;

  return (
    <div className="nav-container">
      <Navbar fluid={true} rounded={true} className={navbarClasses}>
        <div className="container mx-auto px-4 flex justify-between items-center">
          {/* Logo Section */}
          <a
            href="/"
            className="flex items-center text-lg md:text-2xl font-semibold text-primary whitespace-nowrap"
          >
            SERENOTY
          </a>

          {/* Full Menu for Desktop */}
          <div className="flex items-center space-x-4">
            {/* Contact Link - hidden on mobile */}
            <a
              href="mailto:support@serenoty.com"
              className="text-md text-primary hover:underline transition duration-300 sm:block hidden"
            >
              Contact
            </a>

            {/* Log in and Sign up buttons */}
            <div className="flex space-x-2">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border bg-[#f4f4f4] hover:bg-[#e2e2e2] border-transparent text-sm font-semibold rounded-full text-gray-700"
                onClick={() =>
                  (window.location.href = "https://app.serenoty.com/dashboard")
                }
              >
                Log in
              </button>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border bg-black hover:bg-opacity-80 border-transparent text-sm font-semibold rounded-full text-white"
                onClick={() =>
                  (window.location.href = "https://app.serenoty.com/signup")
                }
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Navigation;
