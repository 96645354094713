
// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/research.png";
// import page from "../../assets/pages.svg";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//     const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//     const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//     const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//     const [globeScale, setGlobeScale] = useState(1);
  
//     const handleIntersection = (entries, observer, setVisibility) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setVisibility(true);
//           observer.unobserve(entry.target);
//         }
//       });
//     };
  
//     useEffect(() => {
//       const serenotyElement = document.querySelector("#serenoty");
//       const insightsElement = document.querySelector("#insights");
//       const globeElement = document.querySelector("#globe");
  
//       const options = {
//         root: null,
//         rootMargin: "0px",
//         threshold: 0.8,
//       };
  
//       const serenotyObserver = new IntersectionObserver((entries, observer) => {
//         handleIntersection(entries, observer, setIsSerenotyVisible);
//       }, options);
  
//       const insightsObserver = new IntersectionObserver((entries, observer) => {
//         handleIntersection(entries, observer, setIsInsightsVisible);
//       }, options);
  
//       const globeObserver = new IntersectionObserver((entries, observer) => {
//         handleIntersection(entries, observer, setIsGlobeVisible);
//       }, options);
  
//       serenotyElement && serenotyObserver.observe(serenotyElement);
//       insightsElement && insightsObserver.observe(insightsElement);
//       globeElement && globeObserver.observe(globeElement);
  
//       return () => {
//         serenotyElement && serenotyObserver.unobserve(serenotyElement);
//         insightsElement && insightsObserver.unobserve(insightsElement);
//         globeElement && globeObserver.unobserve(globeElement);
//       };
//     }, []);
  
//     useEffect(() => {
//       const handleResize = () => {
//         setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//       };
  
//       handleResize();
//       window.addEventListener("resize", handleResize);
  
//       return () => {
//         window.removeEventListener("resize", handleResize);
//       };
//     }, []);
  
//     useEffect(() => {
//       if (isGlobeVisible) {
//         let phi = 0;
//         const canvas = document.getElementById("cobe");
  
//         if (canvas) {
//           createGlobe(canvas, {
//             devicePixelRatio: 2,
//             width: 1000,
//             height: 1000,
//             phi: 1.5,
//             opacity: 0.5,
//             theta: 0,
//             dark: 0,
//             diffuse: 3.2,
//             scale: globeScale,
//             mapSamples: 16000,
//             mapBrightness: 4,
//             baseColor: [1, 1, 1],
//             markerColor: [244 / 255, 158 / 255, 128 / 255],
//             glowColor: [1, 1, 1],
//             offset: [0, 0],
//             markers: [
//               { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//               { location: [40.7128, -74.006], size: 0.1 }, // New York
//               { location: [51.5074, -0.1278], size: 0.2 }, // London
//               { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//               { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//               { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//               { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//               { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//               { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//               { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//               { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//               { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//               { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//               { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//               { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//               { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//               { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//               { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//               { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//               { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//               { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//               { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//               { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//               { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//               { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//               { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//               { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//             ],
//             onRender: (state) => {
//               state.phi = phi;
//               phi += 0.009;
//             },
//           });
//         }
//       }
//     }, [isGlobeVisible, globeScale]);
  
//     return (
//       <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="ban-container text-center mt-4"></div>
  
//         <div className="space-y-8">
//           <section id="serenoty" className="py-12 md:py-16 bg-purple">
//             <div className="flex justify-center">
//               <div className="border rounded-xl border-tt border-4 overflow-hidden">
//                 <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//               </div>
//             </div>
//           </section>
  
//           <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//             <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//               <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//                 Say goodbye to endless browsing...
//               </h2>
//               <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                 Elevate your writing with an AI assistant. Receive{" "}
//                 <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//                 suggestions and annotations directly within your workspace.
//               </p>
//             </div>
//             <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//               <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//             </div>
//           </section>
  
//           <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//             <div className="w-full md:w-1/2 relative">
//               <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//                 <div className="p-10">
//                   <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                   <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                     Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                   </p>
//                 </div>
//                 <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                   <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                   <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//                 </div>
//                 <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//               </section>
//             </div>
  
//             <div className="w-full md:w-1/2 relative">
//               <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//                 <div className="p-10">
//                   <h2 className="text-3xl font-extrabold text-[#333]">
//                     Available{" "}
//                     <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                     you are, in every major language
//                   </h2>
//                   <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                     Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                   </p>
//                 </div>
//                 <div className="flex justify-center relative">
//                   <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//                 </div>
//               </section>
//             </div>
//           </div>
         
//         </div>
//       </div>
//     );
//   };
  
//   export default Banner;


// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/research.png";
// import page from "../../assets/pages.svg";
// import logoUSNLM from "../../assets/logos/720px-US-NLM-PubMed-Logo.png";
// import logoNature from "../../assets/logos/1024px-Nature_journal.png";
// import logoArxiv from "../../assets/logos/ArXiv_logo_2022.png";
// import logoGoogleScholar from "../../assets/logos/Google_Scolar.png";
// import logoIEEEXplore from "../../assets/logos/IEEE-xplore-logo.png";
// import logoResearchGate from "../../assets/logos/ResearchGate_icon_SVG.png";
// import logoScienceDirect from "../../assets/logos/ScienceDirect_logo_2020.png";
// import logoSemantic from "../../assets/logos/Semantic.png";
// import logoWLY from "../../assets/logos/WLY.png";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>

//         {/* Logos Section */}
//         <section id="logos" className="py-12 md:py-16 bg-white">
//           <h2 className="text-2xl font-extrabold text-center mb-6">Trusted Sources</h2>
//           <div className="flex flex-wrap justify-center items-center space-x-4 space-y-4">
//             <img src={logoUSNLM} alt="US NLM" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoNature} alt="Nature Journal" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoArxiv} alt="ArXiv" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoGoogleScholar} alt="Google Scholar" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoIEEEXplore} alt="IEEE Xplore" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoResearchGate} alt="ResearchGate" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoScienceDirect} alt="ScienceDirect" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoSemantic} alt="Semantic Scholar" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//             <img src={logoWLY} alt="WLY" className="h-12 transition-transform duration-200 transform hover:scale-105" />
//           </div>
//         </section>
         
//       </div>
//     );
//   };
  
// export default Banner;


// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/notephoto.png";
// import serenoty from "../../assets/research.png";
// import page from "../../assets/pages.svg";
// import logoUSNLM from "../../assets/logos/Logo/720px-US-NLM-PubMed-Logo.png";
// import logoNature from "../../assets/logos/Logo/1024px-Nature_journal.png";
// import logoArxiv from "../../assets/logos/Logo/ArXiv_logo_2022.png";
// import logoGoogleScholar from "../../assets/logos/Logo/google_scholar.jpg";
// import logoIEEEXplore from "../../assets/logos/Logo/IEEE-xplore-logo.png";
// import logoResearchGate from "../../assets/logos/Logo/ResearchGate_icon_SVG.png";
// import logoScienceDirect from "../../assets/logos/Logo/ScienceDirect_logo_2020.png";
// import logoSemantic from "../../assets/logos/Logo/Semantic.png";
// import logoWLY from "../../assets/logos/Logo/WLY.png";
// import createGlobe from "cobe";
// import "./banner.css";

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [244 / 255, 158 / 255, 128 / 255],
//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//         <section id="serenoty" className="py-12 md:py-16 bg-purple">
//           <div className="flex justify-center">
//             <div className="border rounded-xl border-tt border-4 overflow-hidden">
//               <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//             </div>
//           </div>
//         </section>

//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Elevate your writing with an AI assistant. Receive{" "}
//               <span className="bg-secondary text-tt px-2 py-1 rounded-full font-semibold">context-aware</span>{" "}
//               suggestions and annotations directly within your workspace.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//             <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Easily manage and process PDFs up to 20,000 pages with uncompromised efficiency and speed.
//                 </p>
//               </div>
//               <div className="p-8 flex flex-col items-center justify-end text-[#e1e1e1] relative">
//                 <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]">20K Pages</span>
//                 <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="20K Pages Feature" />
//               </div>
//               <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
//             </section>
//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-secondary px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>

      
//         <section id='logos' className='py-16 overflow-hidden'>
//         <div className='container mx-auto text-center'>
//           <h2 className='text-3xl font-extrabold mb-12'>Trusted Research Sources</h2>
//           <div className="relative w-full">
//             <div className="flex animate-infinite-scroll">
//               {[
//                 { src: logoUSNLM, url: 'https://www.nlm.nih.gov/' },
//                 { src: logoNature, url: 'https://www.nature.com/' },
//                 { src: logoArxiv, url: 'https://arxiv.org/' },
//                 { src: logoGoogleScholar, url: 'https://scholar.google.com/' },
//                 { src: logoIEEEXplore, url: 'https://ieeexplore.ieee.org/' },
//                 { src: logoResearchGate, url: 'https://www.researchgate.net/' },
//                 { src: logoScienceDirect, url: 'https://www.sciencedirect.com/' },
//                 { src: logoSemantic, url: 'https://www.semanticscholar.org/' },
//                 { src: logoWLY, url: 'https://www.wiley.com/' }
//               ].map((logo, index) => (
//                 <a 
//                   key={index}
//                   href={logo.url} 
//                   target="_blank" 
//                   rel="noopener noreferrer" 
//                   className="flex-shrink-0 mx-16 group"
//                 >
//                   <img
//                     src={logo.src}
//                     alt={`Research Logo ${index + 1}`}
//                     className="h-16 w-auto object-contain grayscale group-hover:grayscale-0 transition-all duration-300 transform group-hover:scale-110"
//                   />
//                   <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-sm mt-2">
//                     Visit Site
//                   </div>
//                 </a>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>

//       </div>
//     </div>
//   );
// };

// export default Banner;



// import React, { useState, useEffect } from "react";
// import { Syringe, Briefcase, Microscope, BookOpenCheck } from "lucide-react";
// import pic1 from "../../assets/tumor.png";
// import serenoty from "../../assets/research.png";
// import page from "../../assets/pages.svg";
// import logoUSNLM from "../../assets/logos/Logo/720px-US-NLM-PubMed-Logo.png";
// import logoNature from "../../assets/logos/Logo/1024px-Nature_journal.png";
// import logoArxiv from "../../assets/logos/Logo/ArXiv_logo_2022.png";
// import logoGoogleScholar from "../../assets/logos/Logo/google_scholar.jpg";
// import logoIEEEXplore from "../../assets/logos/Logo/IEEE-xplore-logo.png";
// import logoResearchGate from "../../assets/logos/Logo/ResearchGate_icon_SVG.png";
// import logoScienceDirect from "../../assets/logos/Logo/ScienceDirect_logo_2020.png";
// import logoSemantic from "../../assets/logos/Logo/Semantic.png";
// import logoWLY from "../../assets/logos/Logo/WLY.png";
// import createGlobe from "cobe";
// import "./banner.css";
// import { Check } from 'lucide-react';

// const Banner = () => {
//   const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
//   const [isInsightsVisible, setIsInsightsVisible] = useState(false);
//   const [isGlobeVisible, setIsGlobeVisible] = useState(false);
//   const [globeScale, setGlobeScale] = useState(1);

//   const handleIntersection = (entries, observer, setVisibility) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         setVisibility(true);
//         observer.unobserve(entry.target);
//       }
//     });
//   };

//   useEffect(() => {
//     const serenotyElement = document.querySelector("#serenoty");
//     const insightsElement = document.querySelector("#insights");
//     const globeElement = document.querySelector("#globe");

//     const options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 0.8,
//     };

//     const serenotyObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsSerenotyVisible);
//     }, options);

//     const insightsObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsInsightsVisible);
//     }, options);

//     const globeObserver = new IntersectionObserver((entries, observer) => {
//       handleIntersection(entries, observer, setIsGlobeVisible);
//     }, options);

//     serenotyElement && serenotyObserver.observe(serenotyElement);
//     insightsElement && insightsObserver.observe(insightsElement);
//     globeElement && globeObserver.observe(globeElement);

//     return () => {
//       serenotyElement && serenotyObserver.unobserve(serenotyElement);
//       insightsElement && insightsObserver.unobserve(insightsElement);
//       globeElement && globeObserver.unobserve(globeElement);
//     };
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (isGlobeVisible) {
//       let phi = 0;
//       const canvas = document.getElementById("cobe");

//       if (canvas) {
//         createGlobe(canvas, {
//           devicePixelRatio: 2,
//           width: 1000,
//           height: 1000,
//           phi: 1.5,
//           opacity: 0.5,
//           theta: 0,
//           dark: 0,
//           diffuse: 3.2,
//           scale: globeScale,
//           mapSamples: 16000,
//           mapBrightness: 4,
//           baseColor: [1, 1, 1],
//           markerColor: [0.878, 0.620, 1],

//           glowColor: [1, 1, 1],
//           offset: [0, 0],
//           markers: [
//             { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
//             { location: [40.7128, -74.006], size: 0.1 }, // New York
//             { location: [51.5074, -0.1278], size: 0.2 }, // London
//             { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
//             { location: [48.8566, 2.3522], size: 0.08 }, // Paris
//             { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
//             { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
//             { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
//             { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
//             { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
//             { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
//             { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
//             { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
//             { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
//             { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
//             { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
//             { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
//             { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
//             { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
//             { location: [37.5665, 126.978], size: 0.07 }, // Seoul
//             { location: [41.9028, 12.4964], size: 0.07 }, // Rome
//             { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
//             { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
//             { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
//             { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
//             { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
//             { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
//           ],
//           onRender: (state) => {
//             state.phi = phi;
//             phi += 0.009;
//           },
//         });
//       }
//     }
//   }, [isGlobeVisible, globeScale]);

//   const logos = [
//     { src: logoUSNLM, url: 'https://www.nlm.nih.gov/' },
//     { src: logoNature, url: 'https://www.nature.com/' },
//     { src: logoArxiv, url: 'https://arxiv.org/' },
//     { src: logoGoogleScholar, url: 'https://scholar.google.com/' },
//     { src: logoIEEEXplore, url: 'https://ieeexplore.ieee.org/' },
//     { src: logoResearchGate, url: 'https://www.researchgate.net/' },
//     { src: logoScienceDirect, url: 'https://www.sciencedirect.com/' },
//     { src: logoSemantic, url: 'https://www.semanticscholar.org/' },
//     { src: logoWLY, url: 'https://www.wiley.com/' }
//   ];

//   return (
//     <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
//       <div className="ban-container text-center mt-4"></div>

//       <div className="space-y-8">
//      <section id="serenoty" className="py-12 md:py-16 bg-purple">
//   <div className="flex justify-center">
//     <div className="border rounded-xl border-tt border-4 overflow-hidden">
//       <img src={serenoty} alt="Serenoty AI" className="object-cover" />
//     </div>
//   </div>

//   {/* Refined layout with reordered list and new phrasing */}
//   <div className="mt-8 px-8 flex flex-col md:flex-row justify-between items-start md:items-center">
//     <div className="md:w-1/2">
//       <h3 className="text-4xl font-bold text-black leading-tight">
//         Instantly access and process documents with ease
//       </h3>
//       <p className="mt-4 text-black text-lg leading-relaxed">
//         Use an AI-powered assistant to efficiently review any document or collection of files. Ask questions, extract relevant details, and get summaries in seconds.
//       </p>
//     </div>

//     <ul className="mt-6 md:mt-0 md:w-1/2 ml-10 space-y-3 text-lg text-black list-none">
//       <li className="flex items-center">
//         <Check className="text-black text-lg mr-2" /> Handles PDFs, Word files, PowerPoints, and images
//       </li>
//       <li className="flex items-center">
//         <Check className="text-black text-lg mr-2" /> Recognizes text through OCR
//       </li>
//       <li className="flex items-center">
//         <Check className="text-black text-lg mr-2" /> Manage multiple documents in one place
//       </li>
//       <li className="flex items-center">
//         <Check className="text-black text-lg mr-2" /> Search research papers from over 10+ research databases
//       </li>
//       <li className="flex items-center">
//         <Check className="text-black text-lg mr-2" /> Real-time source analysis
//       </li>
//     </ul>
//   </div>
// </section>




//         <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
//           <div className="lg:w-1/2 p-10 flex flex-col justify-center">
//             <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-[#333]">
//               Say goodbye to endless browsing...
//             </h2>
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//               Get the information you need faster. Enjoy context-aware suggestions and focus on what matters most.
//             </p>
//           </div>
//           <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
//             <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
//           </div>
//         </section>

//         <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
//           <div className="w-full md:w-1/2 relative">
//           <section className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//   <div className="p-10">
//     <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
//     <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//       Easily manage and process PDFs up to 50,000 pages with uncompromised efficiency and speed.
//     </p>
//   </div>
//   <div className="p-8 flex flex-col items-center justify-end relative">
//     {/* Change the color of this span to #e09eff */}
//     <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]" style={{ color: '#e09eff' }}>
//       50K Pages
//     </span>
//     <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="50K Pages Feature" />
//   </div>
//   <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
// </section>

//           </div>

//           <div className="w-full md:w-1/2 relative">
//             <section id="globe" className="flex flex-col justify-between h-full bg-[#F6F5F4] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
//               <div className="p-10">
//                 <h2 className="text-3xl font-extrabold text-[#333]">
//                   Available{" "}
//                   <span className="text-3xl font-extrabold text-[#e09eff] px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
//                   you are, in every major language
//                 </h2>
//                 <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
//                   Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
//                 </p>
//               </div>
//               <div className="flex justify-center relative">
//                 <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
//               </div>
//             </section>
//           </div>
//         </div>
//         <section id='logos' className='py-16 overflow-hidden'>
//   <div className='container mx-auto text-center'>
//     <h2 className='text-3xl font-extrabold mb-12'>Trusted Research Sources</h2>
//     <div className="relative w-full overflow-hidden">
//       <div className="animate-infinite-scroll">
//         {[...logos, ...logos].map((logo, index) => (
//           <a
//             key={index}
//             href={logo.url}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex-shrink-0 mx-16 group logo-container"
//           >
//             <img
//               src={logo.src}
//               alt={`Research Logo ${index % logos.length + 1}`}
//               className="h-16 w-auto object-contain grayscale group-hover:grayscale-0 transition-all duration-300 transform group-hover:scale-110"
//               style={{ maxWidth: '150px', maxHeight: '100px' }}
//             />
//           </a>
//         ))}
//       </div>
//     </div>
//   </div>
// </section>



//       </div>
//     </div>
//   );
// };

// export default Banner;


import React, { useState, useEffect } from "react";
import { Syringe, Briefcase, Microscope, BookOpenCheck, Check } from "lucide-react";
import pic1 from "../../assets/tumor.png";
import serenoty from "../../assets/research.png";
import page from "../../assets/pages.svg";
import logoUSNLM from "../../assets/logos/Logo/720px-US-NLM-PubMed-Logo.png";
import logoNature from "../../assets/logos/Logo/1024px-Nature_journal.png";
import logoArxiv from "../../assets/logos/Logo/ArXiv_logo_2022.png";
import logoGoogleScholar from "../../assets/logos/Logo/google_scholar.jpg";
import logoIEEEXplore from "../../assets/logos/Logo/IEEE-xplore-logo.png";
import logoResearchGate from "../../assets/logos/Logo/ResearchGate_icon_SVG.png";
import logoScienceDirect from "../../assets/logos/Logo/ScienceDirect_logo_2020.png";
import logoSemantic from "../../assets/logos/Logo/Semantic.png";
import logoWLY from "../../assets/logos/Logo/WLY.png";
import createGlobe from "cobe";
import "./banner.css";

const Banner = () => {
  const [isSerenotyVisible, setIsSerenotyVisible] = useState(false);
  const [isInsightsVisible, setIsInsightsVisible] = useState(false);
  const [isGlobeVisible, setIsGlobeVisible] = useState(false);
  const [globeScale, setGlobeScale] = useState(1);

  const handleIntersection = (entries, observer, setVisibility) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibility(true);
        observer.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    const serenotyElement = document.querySelector("#serenoty");
    const insightsElement = document.querySelector("#insights");
    const globeElement = document.querySelector("#globe");

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const serenotyObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsSerenotyVisible);
    }, options);

    const insightsObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsInsightsVisible);
    }, options);

    const globeObserver = new IntersectionObserver((entries, observer) => {
      handleIntersection(entries, observer, setIsGlobeVisible);
    }, options);

    serenotyElement && serenotyObserver.observe(serenotyElement);
    insightsElement && insightsObserver.observe(insightsElement);
    globeElement && globeObserver.observe(globeElement);

    return () => {
      serenotyElement && serenotyObserver.unobserve(serenotyElement);
      insightsElement && insightsObserver.unobserve(insightsElement);
      globeElement && globeObserver.unobserve(globeElement);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setGlobeScale(window.matchMedia("(max-width: 768px)").matches ? 0.8 : 1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isGlobeVisible) {
      let phi = 0;
      const canvas = document.getElementById("cobe");

      if (canvas) {
        createGlobe(canvas, {
          devicePixelRatio: 2,
          width: 1000,
          height: 1000,
          phi: 1.5,
          opacity: 0.5,
          theta: 0,
          dark: 0,
          diffuse: 3.2,
          scale: globeScale,
          mapSamples: 16000,
          mapBrightness: 4,
          baseColor: [1, 1, 1],
          markerColor: [0.878, 0.620, 1],

          glowColor: [1, 1, 1],
          offset: [0, 0],
          markers: [
            { location: [37.7595, -122.4367], size: 0.03 }, // San Francisco
            { location: [40.7128, -74.006], size: 0.1 }, // New York
            { location: [51.5074, -0.1278], size: 0.2 }, // London
            { location: [35.6895, 139.6917], size: 0.08 }, // Tokyo
            { location: [48.8566, 2.3522], size: 0.08 }, // Paris
            { location: [55.7558, 37.6176], size: 0.08 }, // Moscow
            { location: [-33.8688, 151.2093], size: 0.04 }, // Sydney
            { location: [-22.9068, -43.1729], size: 0.05 }, // Rio de Janeiro
            { location: [-33.4372, -70.6506], size: 0.04 }, // Santiago
            { location: [-1.2864, 36.8172], size: 0.08 }, // Nairobi
            { location: [-26.2041, 28.0473], size: 0.08 }, // Johannesburg
            { location: [22.5726, 88.3639], size: 0.08 }, // Kolkata
            { location: [19.076, 72.8777], size: 0.08 }, // Mumbai
            { location: [-6.2088, 106.8456], size: 0.09 }, // Jakarta
            { location: [34.0522, -118.2437], size: 0.05 }, // Los Angeles
            { location: [41.8781, -87.6298], size: 0.07 }, // Chicago
            { location: [39.9042, 116.4074], size: 0.07 }, // Beijing
            { location: [-34.6037, -58.3816], size: 0.06 }, // Buenos Aires
            { location: [40.4168, -3.7038], size: 0.07 }, // Madrid
            { location: [37.5665, 126.978], size: 0.07 }, // Seoul
            { location: [41.9028, 12.4964], size: 0.07 }, // Rome
            { location: [39.9526, -75.1652], size: 0.06 }, // Philadelphia
            { location: [55.9533, -3.1883], size: 0.07 }, // Edinburgh
            { location: [28.6139, 77.209], size: 0.07 }, // New Delhi
            { location: [31.2304, 121.4737], size: 0.07 }, // Shanghai
            { location: [-37.8136, 144.9631], size: 0.06 }, // Melbourne
            { location: [-34.397, 150.8931], size: 0.05 }, // Wollongong
          ],
          onRender: (state) => {
            state.phi = phi;
            phi += 0.009;
          },
        });
      }
    }
  }, [isGlobeVisible, globeScale]);

  const logos = [
    { src: logoUSNLM, url: 'https://www.nlm.nih.gov/' },
    { src: logoNature, url: 'https://www.nature.com/' },
    { src: logoArxiv, url: 'https://arxiv.org/' },
    { src: logoGoogleScholar, url: 'https://scholar.google.com/' },
    { src: logoIEEEXplore, url: 'https://ieeexplore.ieee.org/' },
    { src: logoResearchGate, url: 'https://www.researchgate.net/' },
    { src: logoScienceDirect, url: 'https://www.sciencedirect.com/' },
    { src: logoSemantic, url: 'https://www.semanticscholar.org/' },
    { src: logoWLY, url: 'https://www.wiley.com/' }
  ];

  return (
    <div className="font-clashdisplay max-w-7xl mt-20 mx-auto px-4 sm:px-6 lg:px-8">
      <div className="ban-container text-center mt-4"></div>

      <div className="space-y-8">
        <section id="serenoty" className="py-12 md:py-16 bg-purple">
          <div className="flex justify-center">
            <div className="border rounded-xl border-tt border-4 overflow-hidden">
              <img src={serenoty} alt="Serenoty AI" className="object-cover" />
            </div>
          </div>

          {/* Refined layout with original black and white look */}
          <div className="mt-8 px-8 flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="md:w-1/2">
              <h3 className="text-4xl font-bold text-black leading-tight">
                Find and understand info in documents in milliseconds
              </h3>
              <p className="mt-4 text-black text-lg leading-relaxed">
                Generate an AI assistant on top of any document or group of documents. Ask questions to quickly extract and summarize data.
              </p>
            </div>

            <ul className="mt-6 md:mt-0 md:w-1/2 ml-10 space-y-3 text-lg text-black list-none">
              <li className="flex items-center">
                <Check className="text-black text-lg mr-2" /> Handles PDFs, Word files, PowerPoints, and images
              </li>
              <li className="flex items-center">
                <Check className="text-black text-lg mr-2" /> Recognizes text through OCR
              </li>
              <li className="flex items-center">
                <Check className="text-black text-lg mr-2" /> Manage multiple documents in one place
              </li>
              <li className="flex items-center">
                <Check className="text-black text-lg mr-2" /> Search research papers from over 10+ research databases
              </li>
              <li className="flex items-center">
                <Check className="text-black text-lg mr-2" /> Real-time source analysis
              </li>
            </ul>
          </div>
        </section>

        <section id="insights" className="py-8 md:py-16 flex flex-col lg:flex-row justify-between bg-[#f8f8f8] rounded-2xl overflow-hidden" style={{ height: "600px" }}>
          <div className="lg:w-1/2 p-10 flex flex-col justify-center">
            <h2 className="text-3xl leading-relaxed font-extrabold sm:text-4xl text-black">
              Say goodbye to endless browsing...
            </h2>
            <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
              Get the information you need faster. Enjoy context-aware suggestions and focus on what matters most.
            </p>
          </div>
          <div className="lg:w-full relative overflow-hidden flex items-end pb-0" style={{ height: "600px" }}>
            <img src={pic1} alt="Insightful content" className="object-cover w-full h-full rounded-md border border-lg" style={{ transform: "translate(60px, 30px)" }} />
          </div>
        </section>

        <div className="flex flex-col md:flex-row items-stretch space-y-8 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 relative">
            <section className="flex flex-col justify-between h-full bg-[#f8f8f8] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
              <div className="p-10">
                <h3 className="text-3xl font-extrabold text-[#333]">Built for large documents</h3>
                <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
                  Easily manage and process PDFs up to 50,000 pages with uncompromised efficiency and speed.
                </p>
              </div>
              <div className="p-8 flex flex-col items-center justify-end relative">
                <span className="z-10 absolute inset-0 flex items-center justify-center text-[3.5rem] sm:text-[4rem] font-semibold !tracking-[-0.3rem] !leading-[1]" style={{ color: '#333' }}>
                  50K Pages
                </span>
                <img className="inset-0 m-auto max-w-full max-h-full sm:max-w-xs md:max-w-sm lg:max-w-md" src={page} alt="50K Pages Feature" />
              </div>
              <div className="absolute left-0 bottom-0 h-4 w-full z-10"></div>
            </section>
          </div>

          <div className="w-full md:w-1/2 relative">
            <section id="globe" className="flex flex-col justify-between h-full bg-[#f8f8f8] border border-tt rounded-2xl overflow-hidden py-10 md:py-16 lg:py-20 mb-8 lg:mb-0">
              <div className="p-10">
                <h2 className="text-3xl font-extrabold text-[#333]">
                  Available{" "}
                  <span className="text-3xl font-extrabold text-[#333] px-2 py-1 rounded-full font-semibold">wherever</span>{" "}
                  you are, in every major language
                </h2>
                <p className="py-2 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">
                  Serenoty supports over 100 languages, including French, Spanish, Arabic, Chinese, German, Japanese, and many more.
                </p>
              </div>
              <div className="flex justify-center relative">
                <canvas id="cobe" className="w-full md:w-2/3 lg:w-1/2" style={{ width: "500px", height: "500px" }} width="1000" height="1000"></canvas>
              </div>
            </section>
          </div>
        </div>

        <section id="logos" className="py-16 overflow-hidden">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-extrabold mb-12">Trusted Research Sources</h2>
            <div className="relative w-full overflow-hidden">
              <div className="animate-infinite-scroll">
                {[...logos, ...logos].map((logo, index) => (
                  <a
                    key={index}
                    href={logo.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-shrink-0 mx-16 group logo-container"
                  >
                    <img
                      src={logo.src}
                      alt={`Research Logo ${index % logos.length + 1}`}
                      className="h-16 w-auto object-contain grayscale group-hover:grayscale-0 transition-all duration-300 transform group-hover:scale-110"
                      style={{ maxWidth: '150px', maxHeight: '100px' }}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Banner;
