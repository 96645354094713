// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react'; // Assuming Chevron icons for accordion

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-200 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center"
//       >
//         <h3 className="text-lg font-semibold">
//           {question}
//         </h3>
//         {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//       </button>
//       {isOpen && <p className="mt-2 text-sm text-gray-500">{answer}</p>}
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty's mission?",
//       answer: "Serenoty aims to accelerate information extraction and enhance the writing experience for researchers, professionals, and students, streamlining their workflow and improving productivity."
//     },
//     {
//       question: "What languages does Serenoty support?",
//       answer: "Serenoty currently supports over 50 languages, enabling a diverse range of users to benefit from our services."
//     },
//     {
//       question: "How does Serenoty ensure the accuracy of information?",
//       answer: "Serenoty utilizes advanced algorithms and a comprehensive database to verify information, ensuring users receive reliable and accurate data."
//     },
//     {
//       question: "Can Serenoty integrate with other software?",
//       answer: "Yes, Serenoty offers integration capabilities with various platforms to provide a seamless user experience."
//     },
//     {
//       question: "What support options does Serenoty offer?",
//       answer: "Serenoty provides email support, a comprehensive knowledge base, and a community forum for all users."
//     },
//   ];

//   return (
//     <section className="bg-white text-gray-700 py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem key={index} question={faq.question} answer={faq.answer} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;

// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react'; // Assuming Chevron icons for accordion

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-200 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center"
//       >
//         <h3 className="text-lg font-semibold">
//           {question}
//         </h3>
//         {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//       </button>
//       {isOpen && <p className="mt-2 text-sm text-gray-500">{answer}</p>}
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
   
//     {
//       question: "What languages does Serenoty support?",
//       answer: "Serenoty currently supports over 50 languages, enabling a diverse range of users to benefit from our services."
//     },
//     {
//       question: "How does Serenoty ensure the accuracy of information?",
//       answer: "Serenoty utilizes advanced algorithms and a comprehensive database to verify information, ensuring users receive reliable and accurate data."
//     },
//     {
//       question: "Can Serenoty integrate with other software?",
//       answer: "Yes, Serenoty is currently in the process of integrating our platform with Google Chrome, and some of your favorite research platforms such as PubMed and ResearchGate. These integrations will make it easier for users to access and extract research papers from common platforms they visit. Stay tuned for updates!"
//     },
//     {
//       question: "Does Serenoty only support files? What about audios and videos?",
//       answer: "Currently, the beta version of Serenoty supports files and text. However, in the upcoming alpha release, we are expanding our capabilities to interact with audio and video content. This means users will soon be able to extract information from various media formats, making Serenoty even more versatile and useful for research and content creation."
//     },
//   ];

//   return (
//     <section className="bg-white text-gray-700 py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem key={index} question={faq.question} answer={faq.answer} />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;

// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-200 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg font-semibold">
//           {question}
//         </h3>
//         {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="text-sm text-gray-500">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//         question: "What file formats can I upload to Serenoty AI?",
//         answer: "Serenoty supports a range of file formats, including PDF, DOCX, PNG, JPEG, TXT and more, enabling you to upload and work with your documents seamlessly."
//       },
//       {
//         question: "Can I share my notes and findings with my team?",
//         answer: "Absolutely. Serenoty allows you to share your extracted information and notes with colleagues or friends directly within the platform, enhancing teamwork and productivity."
//       },
//       {
//         question: "How secure is my data?",
//         answer: "Your data's security is our top priority. Documents uploaded to Serenoty are encrypted and stored in the cloud with stringent access controls, ensuring that only you and your authorized collaborators can access them."
//       },
//     ];
  

//   return (
//     <section className="bg-white text-gray-700 py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Minus } from 'lucide-react';



// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg tracking-widest font-semibold">
//           {question}
//         </h3>
//         <span className="rounded-md p-1 hover:bg-secondary">
//           {isOpen ? <Minus className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="text-sm text-primary">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [


//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is an AI research assistant that accelerates the process of gathering insights from scientific literature, making paper writing more efficient. Join hundreds of thousands of researchers who utilize Serenoty to organize and comprehend papers, identify connections, generate ideas with AI assistance, and collaborate with colleagues within a shared workspace",    
     
//      }, {
    
//         question: "What file formats can I upload to Serenoty AI?",
//         answer: "Serenoty supports a range of file formats, including PDF, DOCX, PNG, JPEG, TXT and more, enabling you to upload and work with your documents seamlessly."
//       },

//       {
//         question: "Can I share my notes and findings with my team?",
//         answer: "Absolutely. Serenoty allows you to share your extracted information and notes with colleagues or friends directly within the platform, enhancing teamwork and productivity."
//       },
//       {
//         question: "How secure is my data?",
//         answer: "Your data's security is our top priority. Documents uploaded to Serenoty are encrypted and stored in the cloud with stringent access controls, ensuring that only you and your authorized collaborators can access them."
//       },

      
//     ];
  

//   return (
//     <section className="bg-white text-primary py-6 md:py-10 tracking-widest">
//       <div className="max-w-6xl mx-auto px-4  tracking-widest sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Minus } from 'lucide-react';
// import { Plus } from 'lucide-react';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-primary" // Added text-primary class here
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg tracking-wide font-semibold">
//           {question}
//         </h3>
//         <span className="rounded-md p-1 hover:bg-hover1">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform that accelerates the process of gathering insights from scientific literature, making paper writing more efficient. Join Serenoty to organize and comprehend papers, identify connections, generate ideas with AI assistance, and collaborate with colleagues within a shared workspace",    
//     },
//     {
//       question: "What file formats can I upload to Serenoty AI?",
//       answer: "Serenoty supports a range of file formats, including PDF, DOCX, PNG, JPEG, TXT and more, enabling you to upload and work with your documents seamlessly."
//     },
  
//     {
//       question: "How secure is my data?",
//   answer: (
//     <div className="space-y-2">
//       <p>At Serenoty, we prioritize the security of your data through multiple layers of protection:</p>
//       <ul className="list-disc pl-5 space-y-2">
//         <li>
//           <span className="font-bold">Encryption:</span> Your documents are encrypted during both transmission and storage.
//         </li>
//         <li>
//           <span className="font-bold">Access Control:</span> Only authorized users and collaborators can access your documents, with measures like multi-factor authentication and role-based access controls in place.
//         </li>
//         <li>
//           <span className="font-bold">Cloud Security:</span> We utilize secure cloud storage solutions from reputable providers like Google Cloud Products and Amazon Web Services.
//         </li>
//         <li>
//           <span className="font-bold">Regular Audits:</span> Our systems undergo frequent security audits to maintain the highest standards.
//         </li>
//         <li>
//           <span className="font-bold">Compliance:</span> Serenoty adheres to GDPR, PIPEDA, CCPA, and other data protection regulations to ensure your data is handled responsibly.
//         </li>
//       </ul>
//     </div>
//   )

//     },
//   ];

//   return (
//     <section className="bg-white text-primary py-6 md:py-10 tracking-relaxed">
//       <div className="max-w-6xl mx-auto px-4 tracking-relaxed sm:px-6 lg:px-8">
//       <div data-tf-live="01HYXZA9C1ZZDKKRAV4BMQSM1E"></div><script src="//embed.typeform.com/next/embed.js"></script>
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;



// import React, { useState } from 'react';
// import { Minus } from 'lucide-react';
// import { Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-primary"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg tracking-wide font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-hover1">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to facilitate the exploration and gathering of insights from scientific literature. It enables users to efficiently organize and comprehend research papers, identify connections, and collaborate with colleagues in a shared workspace. Serenoty supports access to a wide range of academic databases, including PubMed, Nature, Wiley, and others."
//     },
//     {
//       question: "What file formats can I upload to Serenoty AI?",
//       answer: "Serenoty supports a variety of file formats, including PDF, DOCX, PNG, JPEG, TXT, and more. Users can easily upload and work with their documents, integrating them with research papers sourced from recognized platforms such as PubMed and Wiley."
//     },
//     {
//       question: "How secure is my data?",
//       answer: (
//         <div className="space-y-2">
//           <p>At Serenoty, we prioritize the security of your data through multiple layers of protection:</p>
//           <ul className="list-disc pl-5 space-y-2">
//             <li>
//               <span className="font-bold">Encryption:</span> Your documents are encrypted during both transmission and storage.
//             </li>
//             <li>
//               <span className="font-bold">Access Control:</span> Only authorized users and collaborators can access your documents, with measures like multi-factor authentication and role-based access controls in place.
//             </li>
//             <li>
//               <span className="font-bold">Cloud Security:</span> We utilize secure cloud storage solutions from reputable providers like Google Cloud Products and Amazon Web Services.
//             </li>
//             <li>
//               <span className="font-bold">Regular Audits:</span> Our systems undergo frequent security audits to maintain the highest standards.
//             </li>
//             <li>
//               <span className="font-bold">Compliance:</span> Serenoty adheres to GDPR, PIPEDA, CCPA, and other data protection regulations to ensure your data is handled responsibly.
//             </li>
//           </ul>
//         </div>
//       )
//     },
//     {
//       question: "Can I access research papers from various academic journals?",
//       answer: "Yes, Serenoty provides access to a wide array of research papers from leading academic journals, including but not limited to PubMed, Nature, and Wiley. Users can easily discover and include relevant research in their work."
//     },
//     {
//       question: "Does Serenoty support citation formats?",
//       answer: "Yes, Serenoty supports citations in various formats, including MLA and APA. You can include these citations within the platform, allowing you to prioritize your search content while Serenoty takes care of the legwork for you."
//     }
//   ];

//   return (
//     <section className="bg-white text-primary py-6 md:py-10 tracking-relaxed">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { Minus } from 'lucide-react';
// import { Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-primary"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg tracking-wide font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-hover1">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to facilitate the exploration and gathering of insights from scientific literature. It enables users to efficiently organize and comprehend research papers, identify connections, and collaborate with colleagues in a shared workspace. Serenoty supports access to a wide range of academic databases, including PubMed, Nature, Wiley, and others."
//     },
//     {
//       question: "What file formats can I upload to Serenoty AI?",
//       answer: "Serenoty supports a variety of file formats, including PDF, DOCX, PNG, JPEG, TXT, and more. Users can easily upload and work with their documents, integrating them with research papers sourced from recognized platforms such as PubMed and Wiley."
//     },
//     {
//       question: "How secure is my data?",
//       answer: (
//         <div className="space-y-2">
//           <p>At Serenoty, we prioritize the security of your data through multiple layers of protection:</p>
//           <ul className="list-disc pl-5 space-y-2">
//             <li>
//               <span className="font-bold">Encryption:</span> Your documents are encrypted during both transmission and storage.
//             </li>
//             <li>
//               <span className="font-bold">Access Control:</span> Only authorized users and collaborators can access your documents, with measures like multi-factor authentication and role-based access controls in place.
//             </li>
//             <li>
//               <span className="font-bold">Cloud Security:</span> We utilize secure cloud storage solutions from reputable providers like Google Cloud Products and Amazon Web Services.
//             </li>
//             <li>
//               <span className="font-bold">Regular Audits:</span> Our systems undergo frequent security audits to maintain the highest standards.
//             </li>
//             <li>
//               <span className="font-bold">Compliance:</span> Serenoty adheres to GDPR, PIPEDA, CCPA, and other data protection regulations to ensure your data is handled responsibly.
//             </li>
//           </ul>
//         </div>
//       )
//     },
//     {
//       question: "Can I access research papers from various academic journals?",
//       answer: "Yes, Serenoty provides access to a wide array of research papers from leading academic journals, including but not limited to PubMed, Nature, and Wiley. Users can easily discover and include relevant research in their work."
//     },
//     {
//       question: "Does Serenoty support citation formats?",
//       answer: "Absolutely! Serenoty makes it super easy to handle citations in styles like MLA, APA, and Chicago. You can include these right within the platform, so everything is neatly organized and formatted for you. This way, you can focus on what really matters—diving deep into your research without getting bogged down by the nitty-gritty of citation formatting. Let Serenoty do the heavy lifting, so you can spend more time exploring and less time stressing about the details. It's all about making your research journey smooth and enjoyable!"
//     }
//   ];

//   return (
//     <section className="bg-white text-primary py-6 md:py-10 tracking-relaxed">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { Minus } from 'lucide-react';
// import { Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-primary"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg tracking-wide font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-hover1">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="mt-4 text-[#333] text-opacity-60 text-base md:text-lg leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to facilitate the exploration and gathering of insights from scientific literature. It enables users to efficiently organize and comprehend research papers, identify connections, and collaborate with colleagues in a shared workspace. Serenoty supports access to a wide range of academic databases, including PubMed, Nature, Wiley, and others."
//     },
//     {
//       question: "What file formats can I upload to Serenoty AI?",
//       answer: "Serenoty supports a variety of file formats, including PDF, DOCX, PNG, JPEG, TXT, and more. Users can easily upload and work with their documents, integrating them with research papers sourced from recognized platforms such as PubMed and Wiley."
//     },
//     {
//       question: "How secure is my data?",
//       answer: (
//         <div className="space-y-2">
//           <p>At Serenoty, we prioritize the security of your data through multiple layers of protection:</p>
//           <ul className="list-disc pl-5 space-y-2">
//             <li>
//               <span className="font-bold">Encryption:</span> Your documents are encrypted during both transmission and storage.
//             </li>
//             <li>
//               <span className="font-bold">Access Control:</span> Only authorized users and collaborators can access your documents, with measures like multi-factor authentication and role-based access controls in place.
//             </li>
//             <li>
//               <span className="font-bold">Cloud Security:</span> We utilize secure cloud storage solutions from reputable providers like Google Cloud Products and Amazon Web Services.
//             </li>
//             <li>
//               <span className="font-bold">Regular Audits:</span> Our systems undergo frequent security audits to maintain the highest standards.
//             </li>
//             <li>
//               <span className="font-bold">Compliance:</span> Serenoty adheres to GDPR, PIPEDA, CCPA, and other data protection regulations to ensure your data is handled responsibly.
//             </li>
//           </ul>
//         </div>
//       )
//     },
//     {
//       question: "Can I access research papers from various academic journals?",
//       answer: "Yes, Serenoty provides access to a wide array of research papers from leading academic journals, including but not limited to PubMed, Nature, and Wiley. Users can easily discover and include relevant research in their work."
//     },
//     {
//       question: "Does Serenoty support citation formats?",
//       answer: "Absolutely! Serenoty makes it super easy to handle citations in styles like MLA, APA, and Chicago. You can include these right within the platform, so everything is neatly organized and formatted for you. This way, you can focus on what really matters—diving deep into your research without getting bogged down by the nitty-gritty of citation formatting. Let Serenoty do the heavy lifting, so you can spend more time exploring and less time stressing about the details. It's all about making your research journey smooth and enjoyable!"
//     }
//   ];

//   return (
//     <section className="bg-white text-primary py-6 md:py-10 tracking-relaxed">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Questions</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={faq.question} // Using question as the key since it should be unique
//               id={`faq${index}`} // Generating a unique ID for ARIA controls
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//         <div className="mt-8 text-center">
//           <p className="text-lg">If you have concerns or questions about your data, or if you'd like to suggest additional features, feel free to reach out to us at:</p>
//           <a href="mailto:firaoll@serenoty.com" className="text-blue-600 underline">firaoll@serenoty.com</a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { Minus, Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-gray-200">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="text-base leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to help you efficiently manage and explore scientific literature."
//     },
//     {
//       question: "Is there a free trial?",
//       answer: "While we don't offer a free trial, we provide a 7-day grace period from the date of your first payment. If you're unsatisfied, you can request a full refund within this period."
//     },
//     {
//       question: "Does Serenoty support OCR?",
//       answer: "Yes, Serenoty supports OCR, allowing you to convert scanned documents into editable text."
//     },
//     {
//       question: "Can I trust Serenoty to give me accurate information?",
//       answer: "Absolutely, Serenoty aggregates data from reliable sources ensuring that the information provided is up-to-date and accurate."
//     },
//     {
//       question: "What file types does Serenoty support?",
//       answer: "Serenoty supports various file formats including PDF, DOCX, PNG, JPEG, and more, making it versatile for different types of documents."
//     },
//     {
//       question: "What is your refund policy?",
//       answer: "If you're not satisfied with Serenoty, you can get a full refund within the 7-day grace period after your first payment."
//     },
//     {
//       question: "Can I cancel my subscription?",
//       answer: "Yes, you can cancel your subscription at any time from your account settings."
//     },
//     {
//       question: "What payment methods do you accept?",
//       answer: "We accept various payment methods including credit cards, PayPal, and bank transfers."
//     },
//     {
//       question: "Is my data secure and confidential?",
//       answer: "Yes, we use advanced security protocols to ensure that your data remains secure and confidential at all times."
//     }
//   ];

//   return (
//     <section className="bg-white py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Questions?</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={index}
//               id={`faq${index}`}
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//         <div className="mt-8 text-center">
//           <p className="text-lg">Have more questions? Contact us.</p>
//           <a href="mailto:support@serenoty.com" className="text-blue-600 underline">support@serenoty.com</a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { Minus, Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-gray-200">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="text-base leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to help you efficiently manage and explore scientific literature."
//     },
//     {
//       question: "Does Serenoty support OCR?",
//       answer: "Yes, Serenoty supports OCR, allowing you to convert scanned documents into editable text."
//     },
//     {
//       question: "Can I trust Serenoty to give me accurate information?",
//       answer: "Absolutely, Serenoty aggregates data from reliable sources ensuring that the information provided is up-to-date and accurate."
//     },
//     {
//       question: "What file types does Serenoty support?",
//       answer: "Serenoty supports various file formats including PDF, DOCX, PNG, JPEG, and more, making it versatile for different types of documents."
//     },
//     {
//       question: "What is your refund policy?",
//       answer: "If you're not satisfied with Serenoty, you can get a full refund within the 7-day grace period after your first payment."
//     },
//     {
//       question: "What payment methods do you accept?",
//       answer: "We accept various payment methods including credit cards, PayPal, and bank transfers."
//     },
//     {
//       question: "Is my data secure and confidential?",
//       answer: "Yes, we use advanced security protocols to ensure that your data remains secure and confidential at all times."
//     }
//   ];

//   return (
//     <section className="bg-white py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Questions?</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={index}
//               id={`faq${index}`}
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//         <div className="mt-8 text-center">
//           <p className="text-lg">Have more questions? Contact us.</p>
//           <a href="mailto:support@serenoty.com" className="text-blue-600 underline">support@serenoty.com</a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


// import React, { useState } from 'react';
// import { Minus, Plus } from 'lucide-react';
// import { motion, AnimatePresence } from 'framer-motion';

// const FAQItem = ({ question, answer, id }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleOpen = () => setIsOpen(!isOpen);

//   return (
//     <div className="border-b-2 border-gray-50 py-4">
//       <button
//         onClick={toggleOpen}
//         className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//         aria-expanded={isOpen}
//         aria-controls={`faq-answer-${id}`}
//       >
//         <h3 className="text-lg font-semibold">{question}</h3>
//         <span className="rounded-md p-1 hover:bg-gray-200">
//           {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
//         </span>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.section
//             id={`faq-answer-${id}`}
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.2 }}
//             className="mt-2 overflow-hidden"
//           >
//             <p className="text-base leading-relaxed">{answer}</p>
//           </motion.section>
//         )}
//       </AnimatePresence>
//     </div>
//   );
// };

// const FAQSection = () => {
//   const faqs = [
//     {
//       question: "What is Serenoty?",
//       answer: "Serenoty is a research assistant platform designed to help you efficiently manage and explore scientific literature."
//     },
//     {
//       question: "Does Serenoty support OCR?",
//       answer: "Yes, Serenoty supports OCR, allowing you to convert scanned documents into editable text."
//     },
//     {
//       question: "Can I trust Serenoty to give me accurate information?",
//       answer: "Absolutely, Serenoty aggregates data from reliable sources ensuring that the information provided is up-to-date and accurate."
//     },
//     {
//       question: "What file types does Serenoty support?",
//       answer: "Serenoty supports various file formats including PDF, DOCX, PNG, JPEG, and more, making it versatile for different types of documents."
//     },
//     {
//       question: "Is my data secure and confidential?",
//       answer: "Yes, we use advanced security protocols to ensure that your data remains secure and confidential at all times."
//     }
//   ];

//   return (
//     <section className="bg-white py-6 md:py-10">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
//         <h2 className="text-3xl font-semibold text-center mb-8">Questions?</h2>
//         <div className="space-y-4">
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={index}
//               id={`faq${index}`}
//               question={faq.question}
//               answer={faq.answer}
//             />
//           ))}
//         </div>
//         <div className="mt-8 text-center">
//           <p className="text-lg">Have more questions? Contact us.</p>
//           <a href="mailto:support@serenoty.com" className="text-blue-600 underline">support@serenoty.com</a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FAQSection;


import React, { useState } from 'react';
import { Minus, Plus } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQItem = ({ question, answer, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="border-b-2 border-gray-50 py-4">
      <button
        onClick={toggleOpen}
        className="text-left w-full flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        aria-expanded={isOpen}
        aria-controls={`faq-answer-${id}`}
      >
        <h3 className="text-lg font-semibold">{question}</h3>
        <span className="rounded-md p-1 hover:bg-gray-200">
          {isOpen ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            id={`faq-answer-${id}`}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-2 overflow-hidden"
          >
            <p className="text-base leading-relaxed">{answer}</p>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "What is Serenoty?",
      answer: "Serenoty is a research assistant platform designed to help you efficiently manage and explore scientific literature."
    },
    {
      question: "Does Serenoty support OCR?",
      answer: "Yes, Serenoty supports OCR, allowing you to convert scanned documents into editable text."
    },
    {
      question: "Can I trust Serenoty to give me accurate information?",
      answer: "Absolutely, Serenoty aggregates data from reliable sources ensuring that the information provided is up-to-date and accurate."
    },
    {
      question: "What file types does Serenoty support?",
      answer: "Serenoty supports various file formats including PDF, DOCX, PNG, JPEG, and more, making it versatile for different types of documents."
    },
    {
      question: "Is my data secure and confidential?",
      answer: "Yes, we use advanced security protocols to ensure that your data remains secure and confidential at all times."
    }
  ];

  return (
    <section className="bg-white py-6 md:py-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-semibold text-center mb-8">Questions?</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              id={`faq${index}`}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
        <div className="mt-8 text-center">
          <p className="text-lg">Have more questions? <a href="mailto:support@serenoty.com" className="text-gray-600 underline">Contact us</a>.</p>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
